import Vue from "vue";
import Router from "vue-router";
import VueSimpleAlert from "vue-simple-alert";
import VueOnlineProp from "vue-online-prop";
import VueSession from 'vue-session'
import App from "./App.vue";
import router from "../src/routes";
import VueResource from 'vue-resource';
import BeeGridTable from "beegridtable";
import "beegridtable/dist/styles/beegridtable.css";
import VueI18n from "vue-i18n";
import jQuery from "jquery";
window.$ = window.jQuery =jQuery;
import VueCookies from 'vue-cookies';

import 'popper.js'
import './assets/app.scss'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
Vue.use(VueCookies)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.config.productionTip = false;
Vue.use(VueSession);
Vue.use(VueSimpleAlert);
Vue.use(VueOnlineProp);
Vue.use(Router);
Vue.use(VueResource);

Vue.use(BeeGridTable, {
  capture: true,
  transfer: true,
  zIndex: 2000,   //set z-index of beegridtable 
});
Vue.use(VueI18n);
Vue.locale = () => { }; //necessary
import jp from "./locale/lang/ja-JP";
const messages = {
  jp: jp,
};
const i18n = new VueI18n({
  locale: "jp",
  messages: messages,
});
new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
