import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Index",
      component: () => import("./views/Index.page.vue"), // jshint ignore:line
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.page.vue"), // jshint ignore:line
    },
    {
      path: '/404',
      name: "NotFound",
      component: () => import("./views/NotFound.vue"), // jshint ignore:line
    },
    {
      path: '/administrator',
      name: "Administrator",
      component: () => import("./views/Dashboard.page.vue"), // jshint ignore:line
    },
    {
      path: '/addUser',
      name: "Create User",
      component: () => import("./views/CreateUser.vue"), // jshint ignore:line
    },
    {
      path: '/updateUser',
      name: "Update User",
      component: () => import("./views/UpdateUser.vue"), // jshint ignore:line
    },
  ],
});
router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = window.$cookies.isKey('token')
  let user = window.$cookies.get("usertoken")
  if (authRequired && !loggedIn) {
    return next({
      path: '/login',
    })
  } else if (!'/'.includes(to.path) && loggedIn) {
    next()
  } else if ('/'.includes(to.path) && loggedIn) {
    if (user.roleId == 1) {
      next({
        path: '/administrator',
      })
    }
    else {
      next()
    }
  } else {
    next()
  }
})


export default router;