<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
};
</script>

<style lang="scss">
@import "./src/assets/scss/abstracts/main";
@import "./src/assets/scss/globals/main";
</style>
